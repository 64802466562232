import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { UseAPI } from "../../../services/UseAPI";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from "react-router-dom";

const KegiatanEditPage = () => {
  const [selectedDateDatang, setSelectedDateDatang] = useState(new Date);
  const [selectedDatePulang, setSelectedDatePulang] = useState(new Date);
  const { id }  = useParams();

  const [data, setData] = useState([])
  const [inputs, setInputs] = useState({});
  const handleSave = (e) => {
    e.preventDefault();
    console.log(e);
    const data = new FormData(e.target);
    const inputs = {};
    for (const entry of data.entries()) {
      inputs[entry[0]] = entry[1];
    }
    setInputs(inputs);
    swal({
      title: "Anda yakin ?",
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((willSave) => {
      if (willSave) {
        UseAPI(`kegiatan/${id}`, inputs, 'POST').then((resp) => {
          if (resp.status === 'ok') {
            setData(resp.data);
            swal(resp.pesan, {
              icon: "success",
            });
          } else {
            swal(resp.pesan, {
              icon: "danger",
            });
          }
        });
      }
    })
  }

  useEffect(() => {
    UseAPI(`kegiatan/${id}`, {}, 'GET').then((resp) => {
      if (resp.status === 'ok') {
        setData(resp.data[0]);
        console.log(resp);
        
      } else {
        swal(resp.message, {
          icon: "danger",
        });
      }
    });
  }, []);


  return (
    <Fragment>
      <PageTitle
        motherMenu="Kegiatan"
        activeMenu="Edit"
        pageContent="Kegiatan"
      />

      <div className="row">
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Edit</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSave}>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Nama Sub/Sesi Kegiatan</label>
                    <div className="col-sm-9">
                      <input
                        name="pesan1"
                        type="text"
                        className="form-control"
                        placeholder="Sub/Sesi Kegiatan"
                        defaultValue={data.pesan1}
                        // value={data.pesan1}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Mulai</label>
                    <div className="col-sm-9">
                        <DatePicker
                            selected={selectedDateDatang}
                            // onChange={handleChange}
                            onChange={(date) => setSelectedDateDatang(date)} // Handle date change
                            showTimeSelect // Enable time selection
                            timeFormat="HH:mm" // Format for the time
                            timeIntervals={30} // Interval for time selection (15 minutes)
                            dateFormat="yyyy-MM-dd HH:mm" // Format for displaying date and time
                            placeholderText="Choose date and time"
                            className="form-control w-200"
                            name="tanggal_kegiatan"
                            defaultValue={data.tanggal_kegiatan}


                        />                        
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Selesai</label>
                    <div className="col-sm-9">
                        <DatePicker
                            selected={selectedDatePulang}
                            onChange={(date) => setSelectedDatePulang(date)} // Handle date change
                            showTimeSelect // Enable time selection
                            timeFormat="HH:mm" // Format for the time
                            timeIntervals={30} // Interval for time selection (15 minutes)
                            dateFormat="yyyy-MM-dd HH:mm" // Format for displaying date and time
                            placeholderText="Choose date and time"
                            className="form-control"
                            name="tanggal_pulang"
                            defaultValue={data.tanggal_pulang}
                        />                        
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-sm btn-primary">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />Simpan
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        
      </div>      

    </Fragment>
  );
};

export default KegiatanEditPage;
