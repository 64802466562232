import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCopy, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup } from 'react-bootstrap';

const KOLOM = [
	{
		Header: '#',
		Cell: ({row}) => (
			<>
				{parseInt(row.id)+1}
			</>
		)
	},	
	{
		Header:'Deskripsi',
		Footer:'Deskripsi',
		accessor:'nama_kegiatan',
		Filter:ColumnFilter
	},
	{
		Header:'Sub/Sesi',
		Footer:'Sub/Sesi',
		accessor:'pesan1',
		Cell:({row}) => {
			return (
				<>{row.original.pesan1}</>
			)
		},
		Filter:ColumnFilter
	}
	,{
		Header:'Kode',
		Footer:'Kode',
		accessor:'kode_kegiatan',
		Filter:ColumnFilter
	}
	,{
		Header:'Waktu Awal',
		Footer:'Waktu Awal',
		accessor:'tanggal_kegiatan',
		Filter:ColumnFilter,
		Cell:({row}) => (
			<>{format(new Date(row.original.tanggal_kegiatan), 'dd MMM yyyy HH:mm')}</>
		)

	}
	,{
		Header:'Waktu Akhir',
		Footer:'Waktu Akhir',
		accessor:'tanggal_pulang',
		Filter:ColumnFilter,
		Cell:({row}) => (
			<>{format(new Date(row.original.tanggal_pulang), 'dd MMM yyyy HH:mm')}</>
		)
	}
	,{
		Header: 'Aksi',
		Cell: ({ row, handleRowAction, handleRowActionDelete, handleDuplicate }) => (
			<>
		<ButtonGroup className="me-2" aria-label="First group">
          <Button variant="primary" onClick={() => handleRowAction(row)} size='sm'>
					<FontAwesomeIcon icon={ faEdit } />
		  </Button>
          <Button variant="warning" onClick={() => handleDuplicate(row)} size='sm'>
					<FontAwesomeIcon icon={ faCopy } />
		  </Button>
          <Button variant="danger" onClick={() => handleRowActionDelete(row)} size='sm'>
					<FontAwesomeIcon icon={ faTrash } />
		  </Button>
        </ButtonGroup>

			</>
		),
	}
]

export default KOLOM;

