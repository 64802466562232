import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import DonutChart from './Dashboard/DonutChart';
import { UseAPI } from '../../../services/UseAPI';
import Tryout from '../Tryout/Tryout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

const Home = () => {
	const [data,setData] = useState([]);
	useEffect(()=>{
		UseAPI('kegiatan/presensi',{},'GET').then((resp)=>{
			setData(resp);
			console.log("resp",resp);
		})
	},[])
	return(
		<>
			{/* <Tryout targetPage="dashboard" /> */}
			<div className="row mb-5">
				{
					data.map((item,key) => (
						<div className="col-xl-4 col-lg-4" key={key}>
							<div className="row">
								<div className="col-xl-12">
								<div className="card">
									<div className="card-body my-3">	
										<div className="row">
											<div className="col-xl-12 col-xxl-12 col-md-12">
												<div className="row">
													<div className="d-flex col-xl-12 col-xxl-12  col-md-12 col-12 mb-4">
														<svg className="me-3" width="14" height="54" viewBox="0 0 14 54" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect x="-6.10352e-05" width="14" height="54" rx="7" fill="#AC39D4"/>
														</svg>
														<div>
															<p className="fs-14 mb-2">{`${item.nama_kegiatan}`} <br />{`${item.pesan1}`}</p>
															<span className="fs-16 font-w600 text-light"><span className="text-black me-2 font-w700">{new Date(item.tanggal_kegiatan).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} - {new Date(item.tanggal_pulang).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span></span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-xl-12 col-xxl-12 col-md-12">
												<div className="row">
													<div className="col-6 mb-4">
														<div className="bg-gradient3 rounded text-center p-3">
															<div className="d-inline-block position-relative donut-chart-sale mb-3 text-white">
																Peserta
															</div>
															<span className="fs-14 text-white d-block">{item.peserta}</span>
														</div>
													</div>
													<div className="col-6 mb-4">
														<div className="bg-gradient3 rounded text-center p-3">
															<div className="d-inline-block position-relative donut-chart-sale mb-3 text-white">
																Presensi
															</div>
															<span className="fs-14 text-white d-block">{item.presensi}</span>
														</div>
													</div>

												</div>
											</div>
										</div>							
									</div>
									<div className="card-footer border-0 pt-0">
										<Link to={"/presensi/"+item.id_kegiatan} className="btn btn-primary d-block btn-lg text-uppercase"> <FontAwesomeIcon icon={faCamera} /> Ambil Presensi</Link>
									</div>
								</div>


								</div>
							</div>
						</div>				
					))
				}
			</div>	

		</>
	)
}
export default Home;