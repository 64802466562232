import React from "react";

import { Link } from "react-router-dom";

const Header = ({ onNote }) => {
  var path = window.location.pathname.split("/");

console.log(path);
  var name = path[path.length - 1];
  var finalName = name;
  // console.log('finalName',finalName);
  //console.log(finalName);
  return ( 
    <div className="header border-bottom" style={{height:'5rem'}}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
                >
                  {finalName == 'tryout' ? 'Ujian' : finalName}
              </div>
            </div>            
            {/* <ul className="navbar-nav header-right">
              <li className="nav-item invoices-btn">
                <Link to={"/"} className="btn btn-primary btn-sm ms-5"><i className="far fa-file-alt fs-20 me-2"></i>My Exam</Link>
              </li>				
            </ul> */}
          </div>  
        </nav>
      </div>
    </div>
  );
};

export default Header;
